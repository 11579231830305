import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";

import {  throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private REST_API = environment.api_url;
  private REST_API_SERVER = environment.api_url_server;

  // options: {
  //   headers?: HttpHeaders | {[header: string]: string | string[]},
  //   observe?: 'body' | 'events' | 'response',
  //   params?: HttpParams|{[param: string]: string | string[]},
  //   reportProgress?: boolean,
  //   responseType?: 'arraybuffer'|'blob'|'json'|'text',
  //   withCredentials?: boolean,
  // }

  constructor(private httpClient: HttpClient) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = error.error ;
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  // private getRequest(url: string){
  //   return this.httpClient.get(this.REST_API+url).pipe(retry(3), catchError(this.handleError));
  // }

  private getRequestServer(url: string){
    let options: {
      headers: HttpHeaders,
      observe: 'body',
      reportProgress: true,
      responseType: 'json',
    }
    return this.httpClient.get(this.REST_API_SERVER+url, options);
  }

  private postRequestServer(url: string, obj: Object){
    let options: {
      headers: HttpHeaders,
      observe: 'body',
      reportProgress: true,
      responseType: 'json',  
    }
    return this.httpClient.post(this.REST_API_SERVER+url, obj, options)
  }


  public getArticoli(id?: number): Observable<Object>{
    if(id!=null)
      return this.getRequestServer(`/articoli/${id}`);
    else
      return this.getRequestServer(`/articoli`);
  }
  
  public getInvestimenti(id?: number): Observable<Object>{
    if(id!=null)
      return this.getRequestServer(`/investimenti/${id}`);
    else
      return this.getRequestServer(`/investimenti`);
  }

  public postNewsletter(mail: string): Observable<Object>{
    var formData: any = new FormData();
    formData.append("mail", mail);
    return this.postRequestServer('/newsletter', formData)
  }

  public  postContatto(content: Object): Observable<Object>{
    return this.postRequestServer('/contatto', content)
  }

  public unsubscribe(mail: string): Observable<Object>{
    return this.getRequestServer(`/unsubscribe/${mail}`)
  }
  
}