import { CookieConfig } from "cookie-service-banner";

export const testLibConfig: CookieConfig = {
    header: {
        title: "Banner di consenso sui cookie",
        message: "Questo sito web fa uso di cookie per fornire la migliore esperieza. ",
        domain: "www.houseinvesting.it",
        ga_id: "G-RVJQQ5P2JS",
        color: '#fff',
        bcolor: '#000'
    },
    acceptButton: {
        enable: false,
        accept: "Continua",
        color: '#fff',
        bcolor: '#266433'
    },
    allowButton: {
        enable: true,
        allow: "Accetta Cookie",
        color: '#000',
        bcolor: '#f36e15f5'
    },
    declineButton: {
        enable: true,
        deny: "Rifiuta Cookie",
        color: '#000',
        bcolor: '#fff'
    },
    learnMoreLink: {
        enable: true,
        learnMore: "Mostra dettagli",
        link: "/privacy",
        color: '#3D9BFF'
    },
    review: {
        enable: false,
        message: "Rivedi i consensi",
        color: "",
        bcolor: "",
    }
}