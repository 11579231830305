<section once="footers" class="cid-rW2QgTFTZ3" id="footer7-1n">
    <div class="container">
        <span class=""></span>
        <div class="media-container-row align-center mbr-white">
            <div class="row row-links">
                <ul class="foot-menu"> 
                <li class="foot-menu-item mbr-fonts-style display-7">
                    <a class="text-white mbr-bold" [routerLink]="['/investments']">Opportunità</a>
                </li>
                <li class="foot-menu-item mbr-fonts-style display-7">
                    <a class="text-white mbr-bold" [routerLink]="['/howtowork']">Come funziona</a>
                </li>
                <li class="foot-menu-item mbr-fonts-style display-7">
                    <a class="text-white mbr-bold" [routerLink]="['/blog']">Blog</a>
                </li>
                <li class="foot-menu-item mbr-fonts-style display-7">
                        <a class="text-white mbr-bold" [routerLink]="['/contact']">Mettiti in contatto</a>
                </li></ul>
            </div>
            <!-- <div class="row social-row">
                <div class="social-list align-right pb-2">
                <div class="soc-item">
                        <a href="#" target="_blank">
                            <span class="jam jam-twitter mbr-iconfont mbr-iconfont-social"></span>
                        </a>
                    </div><div class="soc-item">
                        <a href="#" target="_blank">
                            <span class="jam jam-facebook mbr-iconfont mbr-iconfont-social"></span>
                        </a>
                    </div><div class="soc-item">
                        <a href="/" target="_blank">
                            <span class="jam jam-youtube mbr-iconfont mbr-iconfont-social"></span>
                        </a>
                    </div></div>
            </div> -->
            <div class="row row-copirayt">
                <p class="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">House Investing Srl - PI 03916181203</p>
                <p class="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">All Right Reserved {{currentYear}}</p>
            </div>
        </div>
    </div>
</section>