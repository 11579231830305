<section class="header6 cid-rW2Rn0e3VA mbr-fullscreen mbr-parallax-background" id="header6-1q">
    <div class="mbr-overlay" style="opacity: 0.2; background-color: rgb(255, 255, 255);">
    </div>

    <div class="container">
        <div class="row justify-content-md-center">
            <div class="mbr-white col-md-10">
                <h1 class="mbr-section-title align-center mbr-bold pb-3 mbr-fonts-style display-1">SCOPRI COME INVESTIRE IN IMMOBILI</h1>                
            </div>
        </div>
    </div>

    <div class="mbr-arrow hidden-sm-down" aria-hidden="true">
        <a  (click)="scroll(next)">
            <i class="mbri-down mbr-iconfont"></i>
        </a>
    </div>
</section>

<section class="timeline2 cid-rW2SBb1t68" id="timeline2-1u" #next>
    <div class="container align-center">
        <h2 class="mbr-section-title pb-3 mbr-fonts-style display-2">
            I 6 passi che ti porteranno alla tua nuova rendita</h2>
        <div class="container timelines-container" mbri-timelines="">
            <!--1-->
            <div class="row timeline-element reverse separline">
                <span class="iconsBackground">
                    <span class="mbr-iconfont mbri-one"></span>
                </span>          
                <div class="col-xs-12 col-md-6 align-left">
                    <div class="timeline-text-content">
                        <h4 class="mbr-timeline-title pb-3 mbr-fonts-style display-5">Valuta l'opportunità di rendita immobiliare</h4>
                        <p class="mbr-timeline-text mbr-fonts-style display-7">
                            House Investing ti offre la possibilità di investire in immobili chiavi in mano nelle principali città italiane.  Le case proposte in vendita sono sempre ristrutturate e arredate in stile moderno e si collocano vicino importanti università, grandi ospedali o centri storici.
                        </p>
                     </div>
                </div>
            </div>
            <!--2-->
            <div class="row timeline-element  separline">
                <span class="iconsBackground">
                    <span class="mbr-iconfont mbri-two"></span>
                </span>
                <div class="col-xs-12 col-md-6 align-left ">
                    <div class="timeline-text-content">
                        <h4 class="mbr-timeline-title pb-3 mbr-fonts-style display-5">
                            Visiona e scarica la documentazione </h4>
                        <p class="mbr-timeline-text mbr-fonts-style display-7">
                            Una volta individuata un’opportunità, entra nella pagina dedicata e scopri tutte le caratteristiche, come ad esempio: i dati finanziari, la zona di ubicazione, la planimetria, le foto, la gestione della locazione post acquisto e tante altre info.
                        </p>
                    </div>
                </div>
            </div>
            <!--3-->
            <div class="row timeline-element reverse separline">
                <span class="iconsBackground">
                    <span class="mbr-iconfont mbri-three"></span>
                </span>
                <div class="col-xs-12 col-md-6 align-left">
                    <div class="timeline-text-content">
                        <h4 class="mbr-timeline-title pb-3 mbr-fonts-style display-5">
                            Recati all’Open House</h4>      
                        <p class="mbr-timeline-text mbr-fonts-style display-7">
                            Finite le analisi dietro la scrivania, è ora di recarti a visionare la bellezza della casa personalmente. Contattaci tramite mail o chiamaci per fissare il tuo slot dedicato il giorno dell’Open House. La nostra squadra sarà lieta di mostrarti come l’immobile sarà in grado di generare un’ottima rendita passiva.
                        </p>
                    </div>
                </div>
            </div>
            <!--4-->
            <div class="row timeline-element  separline">
                <span class="iconsBackground">
                    <span class="mbr-iconfont mbri-four"></span>
                </span>
                <div class="col-xs-12 col-md-6 align-left ">
                    <div class="timeline-text-content">
                        <h4 class="mbr-timeline-title pb-3 mbr-fonts-style display-5">
                            Fai la tua offerta</h4>
                        <p class="mbr-timeline-text mbr-fonts-style display-7">
                            Dopo l’Open House avrai una settimana di tempo per presentare un’offerta. Il nostro team rimarrà sempre a tua disposizione per ogni dubbio.
                        </p>
                    </div>
                </div>
            </div>
            <!--5-->
            <div class="row timeline-element reverse separline">
                <span class="iconsBackground">
                    <span class="mbr-iconfont mbri-five"></span>
                </span>
                <div class="col-xs-12 col-md-6 align-left">
                    <div class="timeline-text-content">
                        <h4 class="mbr-timeline-title pb-3 mbr-fonts-style display-5">Diventa proprietario</h4>
                        <p class="mbr-timeline-text mbr-fonts-style display-7">
                            Se la tua offerta è risultata la migliore, ti contatteremo per fissare la data di rogito e sviluppare insieme le pratiche bancarie (nel caso tua abbia deciso di utilizzare la leva finanziaria).
                        </p>
                    </div>
                </div>
            </div>
            <!--6-->
            <div class="row timeline-element">
                <span class="iconsBackground">
                    <span class="mbr-iconfont mbri-six"></span>
                </span>
                <div class="col-xs-12 col-md-6 align-left ">
                    <div class="timeline-text-content">
                        <h4 class="mbr-timeline-title pb-3 mbr-fonts-style display-5">Ottieni la rendita costante</h4>
                        <p class="mbr-timeline-text mbr-fonts-style display-7">
                            Ora non ti rimane che goderti la tua nuova entrata. Potrai locare l’immobile personalmente oppure affidarti a noi per la gestione. Se scegli l’opzione di gestione, saremo noi che ogni mese invieremo sul tuo conto corrente la rendita derivante dalla locazione e ci occuperemo per ogni evenienza del tuo nuovo immobile, come se fosse il nostro.
                        </p>
                    </div>
                </div>
            </div>
            <!--7-->
            
            <!--8-->
            
            <!--9-->
            
            <!--10-->
            
            <!--11-->
            
            <!--12-->
            
        </div>
    </div>
</section>

<section class="counters5 counters cid-rW2S64DPms" id="counters5-1r">
    <div class="container pt-4 mt-2">
        <h2 class="mbr-section-title pb-3 align-center mbr-fonts-style display-2">I vantaggi di investire con House Investing</h2>
        
        <div class="row justify-content-center">
            <div class="cards-block">
                <div class="cards-container">
                    <div class="card px-3 align-left col-12">
                        <div class="panel-item p-4 d-flex">
                            <div class="card-img pr-3">
                                <h3 class="img-text d-flex align-items-center justify-content-center">
                                    1
                                </h3>
                            </div>
                            <div class="card-text">
                                <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7">Tempo</h4>
                                <p class="mbr-content-text mbr-fonts-style display-7">
                                    <span class="vantaggi pb-2">Uno dei maggiori vantaggi che offriamo è il risparmio di tempo necessario per acquisire e mettere un immobile a rendita.</span>
                                    <span class="vantaggi pb-2">Pensa solo a quante attività evitate, come ad esempio: studio del mercato immobiliare, ricerca dell’immobile tra migliaia di annunci, ristrutturazione e arredamento (attività tra le più complesse), ricerca degli inquilini più idonei, gestione problematiche relativa alla locazione (eventuali rotture), gestione insolvenze, ecc.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card px-3 align-left col-12">
                        <div class="panel-item p-4 d-flex">
                            <div class="card-img pr-3">
                                <h3 class="img-text d-flex align-items-center justify-content-center">
                                    2
                                </h3>
                            </div>
                            <div class="card-text">
                                <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7">Alti rendimenti e diversificazione del portafogli</h4>
                                <p class="mbr-content-text mbr-fonts-style display-7">
                                    <span class="vantaggi pb-2">Tutte le opportunità di House Investing hanno rendimenti superiori al 5% (annuo) sul capitale investito e nel caso di utilizzo della leva finanziaria il rendimento sul capitale proprio (ROE) è minimo del 10% (annuo). </span>
                                    <span class="vantaggi pb-2">Questi rendimenti sono superiori rispetto a quelli garantiti dai titoli di stato (es. BTP) oltre a permettere una diversificazione del portafogli, investendo nel mercato immobiliare delle principali città italiane.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card px-3 align-left col-12">
                        <div class="panel-item p-4 d-flex">
                            <div class="card-img pr-3">
                                <h3 class="img-text d-flex align-items-center justify-content-center">
                                    3
                                </h3>
                            </div>
                            <div class="card-text">
                                <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7">Formula senza pensieri</h4>
                                <p class="mbr-content-text mbr-fonts-style display-7">
                                    Acquisti un immobile ristrutturato ed arredato, subito pronto per essere locato dal giorno successivo all’acquisto. Se scegli di affidare la gestione al nostro gruppo potrai iniziare a guadagnare senza pensieri. Questo ti garantirà una rendita immediata e costante nel tempo.
                                    </p>
                            </div>
                        </div>
                    </div>
                    <div class="card px-3 align-left col-12">
                        <div class="panel-item p-4 d-flex">
                            <div class="card-img pr-3">
                                <h3 class="img-text d-flex align-items-center justify-content-center">
                                    4
                                </h3>
                            </div>
                            <div class="card-texts">
                                <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7">
                                    Zero commissioni
                                </h4>
                                <p class="mbr-content-text mbr-fonts-style display-7">
                                    Acquistando un immobile tramite House Investing non pagherai le elevate commissioni di agenzia né per l’acquisto né per la locazione.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="card px-3 align-left col-12">
                        <div class="panel-item p-4 d-flex">
                            <div class="card-img pr-3">
                                <h3 class="img-text d-flex align-items-center justify-content-center">
                                    5
                                </h3>
                            </div>
                            <div class="card-texts">
                                <h4 class="mbr-content-title mbr-bold mbr-fonts-style display-7">
                                    Zero rischi
                                </h4>
                                <p class="mbr-content-text mbr-fonts-style display-7">
                                    Se lo vorrai, saremo sempre noi a occuparci del tuo immobile, quindi non correrai mai rischi d’insolvenza né lunghi periodi di sfitto. Nel caso di un inquilino moroso ci occuperemo noi del problema, non ricadendo mai su di te.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-newsletter></app-newsletter>