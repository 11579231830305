import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-to-action-investment',
  templateUrl: './call-to-action-investment.component.html',
  styleUrls: ['./call-to-action-investment.component.scss']
})
export class CallToActionInvestmentComponent implements OnInit {

  emailModel: string;

  constructor() { }

  ngOnInit(): void {
  }

}
