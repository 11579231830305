import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpService } from '../http/http.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  private _success = new Subject<string>();
  successMessage = '';

  @ViewChild('f') myForm: NgForm;
  @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

  constructor(private http: HttpService) { }

  ngOnInit(): void {
    this._success.subscribe(message => this.successMessage = message);
    this._success.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
    
  }

  onSubmit(){
    this.http.postNewsletter(this.myForm.value.email).subscribe(
      responseData =>{
        console.log(responseData);
        this.myForm.reset()
        this._success.next(`Iscrizione avvenuta con successo! A breve riceverai una email.`);
      });
  }  

}
