import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Investimenti } from 'src/app/models/investimenti';
import { HttpService } from 'src/app/core/http/http.service';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})
export class InvestmentComponent implements OnInit, AfterViewInit {
  investment: Investimenti;
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;
  
  active = 1;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  constructor(private actRoute: ActivatedRoute, private http: HttpService) {
    let investment_id = this.actRoute.snapshot.params.id;
    this.http.getInvestimenti(investment_id).subscribe((data: any) => {
      this.investment = data.result;
    })
  }

  getValue(key_: string){
    return this.investment.investmentDetails.find(x => x.key === key_).value
  }
  ngAfterViewInit(): void { }

  ngOnInit(): void { }

  openDoc() {
    window.open(this.investment.planimetria, '_blank', '', true);
  }
}