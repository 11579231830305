<div class="row" *ngIf="investment">
    <div class="card p-3 col-12 col-md-4" *ngFor="let inv of investment">
        <div class="card-wrapper">
            <div class="card-img">
                <a [routerLink]="['/investment',inv.id]" *ngIf="inv.state!=0; else noRef">
                <img src="{{inv.img}}" alt="Unknow" title="" />
                </a>
                <ng-template #noRef><img src="{{inv.img}}" alt="Unknow" title="" class="img-box"/></ng-template>
               
                <div [ngSwitch]="inv.state"  class="card-img-overlay d-flex flex-column">
                    <!-- <div  *ngIf="inv.state==0; then thenBlock else elseBlock"></div>
                    <ng-template #thenBlock><span class="card-title title-overlay bottom-right">In fase di ristrutturazione</span></ng-template>
                    <ng-template #elseBlock><span class="card-title title-overlay bottom-right">In vendita</span></ng-template> -->
                    <!-- <h3 class="card-text font-weight-bold"><span class="mr-auto">Some other title here</span></h3> -->
                    <!-- <div class="mt-auto">Text I want at bottom</div> -->

                        <span *ngSwitchCase="0" class="card-title title-overlay bottom-right">In fase di ristrutturazione</span>
                        <span *ngSwitchCase="1" class="card-title title-overlay bottom-right">In vendita</span>
                        <span *ngSwitchCase="2" class="card-title title-overlay bottom-right">Venduta</span>

                </div>
            </div>
            <div class="card-box">
                <h4 class="card-title mbr-fonts-style display-7">{{inv.title}}</h4>
                <p class="mbr-text mbr-fonts-style display-7">{{inv.description}}
                </p>
                <p class="mbr-text mbr-fonts-style display-7-1"><span class="left">Prezzo</span><span class="right" *ngIf="inv.price>0; else noValue">€
                        {{inv.price}}</span></p>
                <hr class="card-hr" />
                <p class="mbr-text mbr-fonts-style display-7-1"><span class="left">Rendita annuale</span><span
                        class="right" *ngIf="inv.rendita>0; else noValue">€ {{inv.rendita}}</span></p>
                <hr class="card-hr" />
                <p class="mbr-text mbr-fonts-style display-7-1"><span class="left">ROI</span><span
                        class="right" *ngIf="inv.roi>0; else noValue">{{inv.roi}} %</span></p>
                <hr class="card-hr" />
                <p class="mbr-text mbr-fonts-style display-7-1"><span class="left">ROE</span><span
                        class="right" *ngIf="inv.roe>0; else noValue">{{inv.roe}} %</span></p>
                <ng-template #noValue><span class="right">-</span></ng-template>
            </div>
            <div class="mbr-section-btn text-center">
                <a [routerLink]="['/investment',inv.id]" class="btn btn-primary display-4" *ngIf="inv.state!=0">
                    Per saperne di più
                </a>
                <a class="btn btn-primary display-4 disabled" *ngIf="inv.state==0">Presto disponibile!</a>
            </div>
        </div>
    </div>

</div>