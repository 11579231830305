import { Component, OnInit, Inject, ViewChildren, QueryList, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-how-to-work',
  templateUrl: './how-to-work.component.html',
  styleUrls: ['./how-to-work.component.scss']
})
export class HowToWorkComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
  }
}
