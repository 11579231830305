<section class="cid-rWSvnGScFS mbr-fullscreen mbr-parallax-background" id="header2-2m">
    <div class="container align-center">
        <div class="row justify-content-md-center">
            <div class="mbr-white col-md-10">
                <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">
                    Blog e risorse</h1>

                <!-- <p class="mbr-text pb-3 mbr-fonts-style display-5">
                    Fai clic su qualsiasi testo per modificarlo o cambiare lo stile. Seleziona il &nbsp;</p> -->
            </div>
        </div>
    </div>
    <div class="mbr-arrow hidden-sm-down" aria-hidden="true">
        <a (click)="scroll(next)">
            <i class="mbri-down mbr-iconfont"></i>
        </a>
    </div>
</section>

<section class="mbr-section content4 cid-rWSzcMklUK" id="content4-2q" #next>
    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-8">
                <h2 class="align-center pb-3 mbr-fonts-style display-2">
                    Ultimi articoli pubblicati</h2>
            </div>
        </div>
    </div>
</section>

<section class="cid-rWJrFPI1Ss" id="features2-29">
    <div class="container">
        <div class="row">
            <div class="card p-3 col-12 col-md-6" *ngFor="let article of art">
                <div class="card-wrapper">
                    <div class="card-img">
                        <img src="{{article.img}}" alt="Mobirise">
                    </div>
                    <div class="card-box">
                        <h4 class="card-title mbr-fonts-style display-5">
                            {{article.title}}
                        </h4>
                        <p class="mbr-text mbr-fonts-style display-7">
                            {{article.subtitle}}
                        </p>
                    </div>
                    <div class="mbr-section-btn text-center"><a [routerLink]="['/article/', article.id]" class="btn btn-primary display-4">
                            Per saperne di più
                        </a></div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="features2 cid-rWJrFPI1Ss" id="features2-28">
    <div class="container">
        <div class="media-container-row">
            <div class="card p-3 col-12 col-md-6">
                <div class="card-wrapper">
                    <div class="card-img">
                        <a [routerLink]="['/article',0]"><img src="assets/images/mbr-1-1046x695.jpg" alt="Mobirise"
                                title="testo"></a>
                    </div>
                    <div class="card-box">
                        <h4 class="card-title pb-3 mbr-fonts-style display-2">
                            <a [routerLink]="['/article',0]">Nessuna capacità di programmazione</a>
                        </h4>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<app-newsletter></app-newsletter>