import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/core/http/http.service';
import { Articoli } from 'src/app/models/articoli.model';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  art: Articoli;

  constructor(private actRoute: ActivatedRoute,
    private httpService: HttpService) { 
    let article_id = this.actRoute.snapshot.params.id;
    this.httpService.getArticoli(article_id).subscribe(
      (response: any) => {
        this.art = response.result;
      }
    )
  }

  ngOnInit(): void {
  }

}
