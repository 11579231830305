<section class="counters2 counters cid-rW2aZ5afAB" id="counters2-y">
    <div class="container pt-4 mt-2" *ngIf="investment">
        <div class="row">
            <div class="col-12">
                <h2 class="mbr-section-title pb-3  mbr-fonts-style display-2">
                    {{investment.zone}}</h2>
            </div>
            
            <div class="col-12">
                <h3 class="mbr-section-subtitle mbr-fonts-style display-5">
                    {{investment.area}} mq | {{investment.locali}} locali | {{investment.toilet}}
                    bagno<br>{{investment.address}}</h3>
            </div>
        </div>
    </div>
    <div class="container pt-4 mt-2" *ngIf="investment">
        <div class="media-container-row">
            <div class="media-block" style="width: 60%;">
                <div class="mbr-figure">
                    <img src="{{investment.img}}" alt="Unknow">
                </div>
            </div>
            <div class="cards-block" *ngIf="investment">
                <div class="cards-container">
                    <div class="card px-3 align-left col-12 col-md-6">
                        <div class="panel-item p-3">
                            <div class="card-img">
                                <span class="mbr-iconfont pr-2 mbri-home"></span>
                                <h3 class="count py-3 mbr-fonts-style display-5">{{investment.price}} €</h3>
                            </div>
                            <div class="card-text">
                                <h3 class="mbr-content-title mbr-fonts-style display-7-1">
                                    Prezzo immobile</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card px-3 align-left col-12 col-md-6">
                        <div class="panel-item p-3">
                            <div class="card-img">
                                <span class="mbr-iconfont pr-2 mbri-cash"></span>
                                <h3 class="count py-3 mbr-fonts-style display-5">{{investment.rendita}} €</h3>
                            </div>
                            <div class="card-text">
                                <h3 class="mbr-content-title mbr-fonts-style display-7-1">
                                    Rendita annuale</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card px-3 align-left col-12 col-md-6">
                        <div class="panel-item p-3">
                            <div class="card-img">
                                <span class="mbr-iconfont pr-2 mbri-update"></span>
                                <h3 class="count py-3 mbr-fonts-style display-5">{{investment.roi}} % (ROI)</h3>
                            </div>
                            <div class="card-text">
                                <h3 class="mbr-content-title mbr-fonts-style display-7-1">
                                    Ritorno sull'investimento</h3>
                            </div>
                        </div>
                    </div>
                    <div class="card px-3 align-left col-12 col-md-6">
                        <div class="panel-item p-3">
                            <div class="card-img">
                                <span class="mbr-iconfont pr-2 mbri-update"></span>
                                <h3 class="count py-3 mbr-fonts-style display-5">
                                    {{investment.roe}} % (ROE)</h3>
                            </div>
                            <div class="card-texts">
                                <h3 class="mbr-content-title mbr-fonts-style display-7-1">Ritorno sul capitale
                                    proprio</h3>
                                <!-- <p class="mbr-content-text mbr-fonts-style display-7">
                                    Mobirise ti consente di utilizzare facilmente i caratteri Google sul tuo sito web in
                                    modo facile e gratuito.
                                </p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="tabs3 cid-rW2JWSxljJ" id="tabs3-1d">
    <div class="container-fluid">
        <div class="row tabcont">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Progetto</a>
                    <ng-template ngbNavContent>
                        <div class="container" *ngIf="investment">
                            <div class="row">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mbr-element-title mbr-fonts-style pb-2 display-5">
                                        L'OPPORTUNITÀ
                                    </h4>
                                </div>
                                <div class="col-xs-12 col-md-9">
                                    <p class="mbr-section-text mbr-fonts-style display-7" [innerHTML]="getValue('opportunita')"></p>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mbr-element-title mbr-fonts-style pb-2 display-5">
                                        CARATTERISTICHE
                                    </h4>
                                </div>
                                <div class="col-xs-12 col-md-9">
                                    <p class="mbr-section-text mbr-fonts-style display-7" [innerHTML]="getValue('caratteristiche')"></p>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mbr-element-title mbr-fonts-style pb-2 display-5">
                                        LOCATION
                                    </h4>
                                </div>
                                <div class="col-xs-12 col-md-9">
                                    <p class="mbr-section-text mbr-fonts-style display-7" [innerHTML]="getValue('location')"></p>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mbr-element-title mbr-fonts-style pb-2 display-5">
                                        MERCATO
                                    </h4>
                                </div>
                                <div class="col-xs-12 col-md-9">
                                    <p class="mbr-section-text mbr-fonts-style display-7" [innerHTML]="getValue('mercato')"></p>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Gestione</a>
                    <ng-template ngbNavContent>
                        <div class="container" *ngIf="investment">
                            <div class="row">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mbr-element-title mbr-fonts-style pb-2 display-5">
                                        LA LOCAZIONE
                                    </h4>
                                </div>
                                <div class="col-xs-12 col-md-9">
                                    <p class="mbr-section-text mbr-fonts-style display-7" [innerHTML]="getValue('locazione')"></p>
                                </div>
                            </div>
                            <hr />

                            <div class="row">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mbr-element-title mbr-fonts-style pb-2 display-5">
                                        GESTIONE IMMOBLIARE E SOCIETA' DI GESTIONE
                                    </h4>
                                </div>
                                <div class="col-xs-12 col-md-9">
                                    <p class="mbr-section-text mbr-fonts-style display-7" [innerHTML]="getValue('gestione')"></p>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>Dati finanziari</a>
                    <ng-template ngbNavContent>
                        <div class="container">
                            <div class="row">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mbr-element-title mbr-fonts-style pb-2 display-5">
                                        DATI DI SINTESI
                                    </h4>
                                </div>
                                <div class="col-xs-12 col-md-9">
                                    <p class="mbr-section-text mbr-fonts-style display-7" [innerHTML]="getValue('sintesi')"></p>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4">
                    <a ngbNavLink>Documenti</a>
                    <ng-template ngbNavContent>
                        <div class="container">
                            <div class="row">
                                <div class="col-xs-12 col-md-3">
                                    <h4 class="mbr-element-title mbr-fonts-style pb-2 display-5">
                                        DOCUMENTI
                                    </h4>
                                </div>
                                <div class="col-xs-12 col-md-9">
                                    <p class="mbr-section-text  align-center mbr-fonts-style display-7">
                                        <a (click)="openDoc()">Scarica planimetria</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>

</section>
<hr />
<section class="mbr-gallery gallery5 cid-rW2aZ95mPC" id="gallery5-11">
    <div class="container" *ngIf="investment">
        <!-- <h3 class="mbr-section-title align-center mbr-fonts-style display-2">
            
        </h3> -->
        <div class="col-xs-12 col-md-12">
            <app-map [lat]=investment.lat [lon]=investment.lon></app-map>
        </div>
    </div>
</section>
<hr />
<section class="mbr-gallery gallery5 cid-rW2aZ95mPC" id="gallery5-11">
    <div class="container" *ngIf="investment">
        <h3 class="mbr-section-title align-center mbr-fonts-style display-2">
            Galleria
        </h3>
        <ngb-carousel #carousel [interval]="3000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus"
            (slide)="onSlide($event)">
            <ng-template ngbSlide *ngFor="let img of investment.images; index as i">
                <!-- <div class="carousel-caption">
                <h3>My slide {{i + 1}} title</h3>
              </div> -->
                <div class="picsum-img-wrapper">
                    <img [src]="img.value" alt="My image {{i + 1}} description" class="resize">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</section>

<!-- <section class="cid-rWCjQngABx" id="video1-1x">
    <figure class="mbr-figure align-center container">
        <div class="video-block" style="width: 70%;">
            <div><iframe class="mbr-embedded-video" src="https://www.youtube.com/embed/uNCr7NdOJgw?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" width="896" height="504" frameborder="0" allowfullscreen></iframe></div>
        </div>
    </figure>
</section> -->

<app-call-to-action-investment></app-call-to-action-investment>