import { Component, ElementRef, HostBinding, HostListener, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @HostBinding('class.show')isCollapsed = true;

  //  isOpen = false;

  //   @HostListener('document:click', ['$event']) toggleOpen(event: Event) {
  //     this.isOpen = this.elRef.nativeElement.contains(event.target) ? !this.isOpen : false;
  //     // console.log(this.isOpen)
  //     // this.isOpen ? this.renderer.removeClass(this.elRef.nativeElement, "show"):'';
  //   }
  
  //   @HostListener('document:click', ['$event']) toggleClose(event: Event){
  //     console.log(this.elRef.nativeElement.contains(event.target))
  //     this.elRef.nativeElement.classList.contains('show')? this.renderer.removeClass(this.elRef.nativeElement, "show"):''
  //   }
      @HostListener("window:scroll", []) onWindowScroll() { 
          // this.renderer.removeClass(this.elRef.nativeElement, "show")
          this.isCollapsed = true;
       }
  constructor(private elRef: ElementRef,private renderer: Renderer2) {}

  ngOnInit(): void {
  }

}
