import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpService } from 'src/app/core/http/http.service';
import { baseResponse } from 'src/app/models/baseResponse.model';
import { ToastService } from '../shared/toast.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  private email: string;

  constructor(private route: ActivatedRoute, private http: HttpService,
    public toastService: ToastService) { }

  ngOnInit(){
    this.route.params
      .subscribe(
        (params: Params) => {
          this.email = params['mail'];
          if (!this.isEmpty(this.email) || !this.isBlank(this.email)) {
            this.http.unsubscribe(this.email).subscribe(
              (responseData: baseResponse) => {
                this.showSuccess(responseData.result)
              }
            )
          }
        }
      )
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }

  private isEmpty(str) {
    return (!str || 0 === str.length);
  }
  private isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  showStandard() {
    this.toastService.show('I am a standard toast');
  }

  showSuccess(text: string) {
    this.toastService.show('La tua mail è stata rimossa dal nostro archivio', { classname: 'bg-success text-light', delay: 5000 });
  }

  showDanger(dangerTpl) {
    this.toastService.show(dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });
  }
}
