<section class="mbr-section content5 cid-rWSuopB4O8 mbr-parallax-background" id="content5-2h">
    <div class="mbr-overlay" style="opacity: 0.5; background-color: rgb(118, 118, 118);"></div>
    <div class="container">
        <div class="media-container-row" *ngIf="art">
            <div class="title col-12 col-md-8">
                <h2 class="align-center mbr-bold mbr-white pb-3 mbr-fonts-style display-1">
                    {{art.title}}
                </h2>
                <h3 class="mbr-section-subtitle align-center mbr-light mbr-white pb-3 mbr-fonts-style display-5">
                    {{art.subtitle}}
                </h3>
            </div>
        </div>
    </div>
</section>

<!-- <section class="mbr-section article content9 cid-rWSuTR3jQE" id="content9-2j">
    <div class="container">
        <div class="inner-container" style="width: 100%;">
            <hr class="line" style="width: 25%;">
            <div class="section-text align-center mbr-fonts-style display-5" >
                    <span [innerHTML]="art.text"></span>
                </div>
            <hr class="line" style="width: 25%;">
        </div>
        </div>
</section>

<section class="mbr-section article content1 cid-rWSv3YgcW0" id="content2-2l">
    <div class="container">
        <div class="media-container-row">
            <div class="mbr-text col-12 col-md-8 mbr-fonts-style display-7">
                <blockquote> <strong>Costruisci il tuo sito in pochi click</strong> Mobirise ti aiuta a ridurre i tempi di sviluppo fornendoti un editor di siti web flessibile con un'interfaccia drag-and-drop. Mobirise Website Builder crea siti reattivi, retina e <strong>mobile friendly</strong> in pochi click. Mobirise è uno degli strumenti di sviluppo di siti Web più semplici <a href="https://mobirise.co/">disponibili</a> Oggi. Ti dà anche la libertà di sviluppare tanti siti web che desideri dato che si tratta di un'applicazione desktop.</blockquote>
            </div>
        </div>
    </div>
</section> -->

<section class="mbr-section article content1 cid-rWSuUfzLyi" id="content1-2k">
    <div class="container"  *ngIf="art">
        <div class="media-container-row">
            <div class="mbr-text col-12 mbr-fonts-style display-7 col-md-8">
                <span [innerHTML]="art.text"></span></div>
        </div>
    </div>
</section>