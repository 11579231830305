<section class="cid-qTkA127IK8 mbr-fullscreen mbr-parallax-background" id="header2-1">

    <div class="mbr-overlay" style="opacity: 0.5; background-color: rgb(118, 118, 118);"></div>

    <div class="container align-center">
        <div class="row justify-content-md-center">
            <div class="mbr-white col-md-10">
                <h1 class="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">
                    INVESTI IN IMMOBILI</h1>
                <div class="mbr-section-btn">
                    <a class="btn btn-md btn-primary display-4" [routerLink]="['/investments']">VEDI OPPORTUNITÀ</a>
                    <a class="btn btn-md btn-white-outline display-4" [routerLink]="['/howtowork']">SCOPRI COME
                        FUNZIONA</a></div>
            </div>
        </div>
    </div>
    <div class="mbr-arrow hidden-sm-down" aria-hidden="true">
        <a (click)="scroll(next)">
            <i class="mbri-down mbr-iconfont"></i>
        </a>
    </div>
</section>

<section class="features3 cid-rW1Xe3SNiO" id="features3-6" #next>
    <div class="container">
        <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
        <h2 class="mbr-section-title pb-2 mbr-fonts-style display-2">
            Le nostre opportunit&agrave;</h2>
        <app-opportunity-card></app-opportunity-card>
    </div>
</section>

<section class="features12 cid-rW21llaKA9" id="features12-c">

    <div class="container">
        <h2 class="mbr-section-title pb-2 mbr-fonts-style display-2">
            Vantaggi per gli investitori</h2>

        <div class="media-container-row pt-4">
            <div class="block-content align-right">
                <div class="card pl-3 pr-3 pb-2">
                    <div class="mbr-card-img-title">

                        <div class="mbr-crt-title">
                            <h4 class="card-title py-2 mbr-crt-title mbr-fonts-style display-5">Formula senza pensieri
                            </h4>
                        </div>
                    </div>

                    <div class="card-box">
                        <p class="mbr-text mbr-section-text mbr-fonts-style display-7">
                            Acquisti un immobile ristrutturato ed arredato, subito pronto per essere locato dal giorno successivo all’acquisto. 
                        </p>
                    </div>
                </div>

                <div class="card pl-3 pr-3">
                    <div class="mbr-card-img-title">

                        <div class="mbr-crt-title">
                            <h4 class="card-title py-2 mbr-crt-title mbr-fonts-style display-5">
                                Diversificazione</h4>
                        </div>
                    </div>
                    <div class="card-box">
                        <p class="mbr-text mbr-section-text mbr-fonts-style display-7">
                            Investi nel mercato immobliare delle principali città italiane.
                        </p>
                    </div>
                </div>
            </div>

            <div class="mbr-figure m-auto" style="width: 44%;">
                <img src="assets/images/mbr-2-850x567.jpg" alt="Unknow" title="">
            </div>

            <div class="block-content align-left">
                <div class="card pl-3 pr-3 pb-2">
                    <div class="mbr-card-img-title">

                        <div class="mbr-crt-title">
                            <h4 class="card-title py-2 mbr-crt-title mbr-fonts-style display-5">
                                Massimo rendimento minimo sforzo</h4>
                        </div>
                    </div>

                    <div class="card-box">
                        <p class="mbr-text mbr-section-text mbr-fonts-style display-7">Scegliendo l'opzione di gestione ti garantiamo la rendita del tuo immobile. Eliminiamo i rischi di un immobile locato.</p>
                    </div>
                </div>

                <div class="card pl-3 pr-3">
                    <div class="mbr-card-img-title">

                        <div class="mbr-crt-title">
                            <h4 class="card-title py-2 mbr-crt-title mbr-fonts-style display-5">Zero commissioni</h4>
                        </div>
                    </div>
                    <div class="card-box">
                        <p class="mbr-text mbr-section-text mbr-fonts-style display-7">Nessun costo per il proprietario
                            dell'immobile.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="cid-rW2ZhgKiAW" id="video1-1v">

    <figure class="mbr-figure align-center container">
        <div class="video-block" style="width: 70%;">
            <div><iframe class="mbr-embedded-video" src="https://www.youtube.com/embed/uNCr7NdOJgw?rel=0&amp;amp;showinfo=0&amp;autoplay=0&amp;loop=0" width="896" height="504" frameborder="0" allowfullscreen></iframe></div>
        </div>
    </figure>
</section> -->
<app-newsletter></app-newsletter>