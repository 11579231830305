import { Component, OnInit } from '@angular/core';
// import { HttpService } from 'src/app/core/http/http.service';
// import { Investimenti } from 'src/app/models/investimenti';
// import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss']
})
export class InvestmentsComponent implements OnInit {

  // inv: Investimenti[];

  // constructor(private http: HttpService) {}
  constructor() {}

  ngOnInit(): void {
    // this.http.getInvestimenti().pipe(
    //   take(1),
    //   map((data: Investimenti[]) => this.inv = data)
    //  ).subscribe()
  }

}
