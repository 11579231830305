<section class="mbr-section form3 cid-rW21EDeuQj" id="form3-e">
    <div class="container">
        <div class="row justify-content-center">
            <div class="title col-12 col-lg-8">

                <h3 class="mbr-section-subtitle align-center pb-5 mbr-light mbr-fonts-style display-5">
                    Iscriviti per essere aggiornato sulle prossime opportunità</h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-6  col-md-8 " data-form-type="formoid">
                <ngb-alert #selfClosingAlert *ngIf="successMessage" type="success" (closed)="successMessage = ''">{{ successMessage }}
                </ngb-alert>
                <form (ngSubmit)="onSubmit()" method="POST" class="mbr-form form-with-styler"
                    data-form-title="newsletter" #f="ngForm">
                    <div class="row">
                        <div hidden="hidden" data-form-alert="" class="alert alert-success col-12">Grazie per aver
                            compilato il modulo!</div>
                        <div hidden="hidden" data-form-alert-danger="" class="alert alert-danger col-12">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-12 col-lg-6  col-md-8" data-for="email">
                            <input type="email" name="email" placeholder="Email" required email
                                class="form-control display-7" id="email-form3-e" ngModel #email="ngModel">
                            <span class="help-block" *ngIf="!email.valid && email.touched">Please enter a valid
                                email</span>
                        </div>
                        <div class="col-12 col-lg-6 col-md-8 input-group-btn">
                            <button type="submit" class="btn btn-primary display-4"
                                [disabled]="!f.valid">ISCRIZIONE</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>