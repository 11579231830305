import { Component, OnInit } from '@angular/core';
import { Investimenti } from 'src/app/models/investimenti';
import { HttpService } from 'src/app/core/http/http.service';

@Component({
  selector: 'app-opportunity-card',
  templateUrl: './opportunity-card.component.html',
  styleUrls: ['./opportunity-card.component.scss']
})
export class OpportunityCardComponent implements OnInit {

  investment: Investimenti[];

  constructor(private http: HttpService) { 
    this.http.getInvestimenti().subscribe(
      (body: any) =>{ 
        this.investment = body.data;
      })
  }

  ngOnInit(): void {
  }

}
