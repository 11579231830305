import { Component, AfterViewInit, Input } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {

  @Input() lat: number;
  @Input() lon: number;
  private map;

  private initMap(lat: number, lon: number): void {
    this.map = L.map('map', {
      center: [lat, lon],
      zoom: 14,
      minZoom: 9,
      scrollWheelZoom: false
    });
  }

  constructor() { }

  ngAfterViewInit(): void {

    // this.initMap(44.501545, 11.368535);
    this.initMap(this.lat, this.lon);

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1Ijoid2VydDg3IiwiYSI6ImNrOWg1YzBsejA2dnAzZXJ1dnp6amtncGgifQ.HrH2Wi_9-lPbDaDD1XzouA'
    }).addTo(this.map);

    let myIcon = L.icon({
      iconUrl: 'assets/images/mbr-182x184.png',
      iconSize: [30,30],
      popupAnchor: [-3, -76],
    });
    L.marker([this.lat, this.lon], {icon: myIcon}).addTo(this.map);
  }

}
