<section class="mbr-section form1 cid-rW2297fvri" id="form1-h">
    <div class="container">
        <div class="row justify-content-center">
            <div class="title col-12 col-lg-8">
                <h2 class="mbr-section-title pb-3 mbr-fonts-style display-5">Hai domande su House Investing o vuoi maggiori informazioni su un immobile?</h2>
                <hr />
                <h3 class="mbr-section-subtitle pb-3 mbr-fonts-style display-5">Compila i campi qui sotto, scrivici a <a href="mailto:info@houseinvesting.it">info@houseinvesting.it</a> o chiama il <a href="tel:+393792589117">+39 3792589117</a></h3>
                <hr />
            </div>
        </div>
    </div>
    <div class="container" onBeforeUnload>
        <div class="row justify-content-center">
            <div class="media-container-column col-lg-8" data-form-type="formoid">
                <!---Formbuilder Form--->
                <form class="mbr-form form-with-styler" data-form-title="Contact Form"
                    #postForm="ngForm" (ngSubmit)="onCreatePost(postForm.value)">
                    <div class="row">
                        <div *ngIf="success" data-form-alert="" class="alert alert-success col-12">Ti ringraziamo averci contattato! Ti risponderemo nel piu breve tempo possibile.</div>
                        <div hidden="hidden" data-form-alert-danger="" class="alert alert-danger col-12">
                        </div>
                    </div>
                    <div class="dragArea row">
                        <div class="col-md-4  form-group" data-for="name">
                            <label for="name-form1-h" class="form-control-label mbr-fonts-style display-7">Nome</label>
                            <input type="text" name="name" data-form-field="Name" class="form-control display-7" id="name-form1-h" required ngModel >
                        </div>
                        <div class="col-md-4  form-group" data-for="email">
                            <label for="email-form1-h" class="form-control-label mbr-fonts-style display-7">Email</label>
                            <input type="email" name="email" data-form-field="Email" required class="form-control display-7" id="email-form1-h" email ngModel #email="ngModel">
                            <span class="help-block" *ngIf="!email.valid && email.touched">Per favore inserisci una email valida</span>
                        </div>
                        <div data-for="phone" class="col-md-4  form-group">
                            <label for="phone-form1-h" class="form-control-label mbr-fonts-style display-7">Telefono</label>
                            <input type="text" name="phone" data-form-field="Phone" class="form-control display-7" id="phone-form1-h" ngModel>
                        </div>
                        <div data-for="message" class="col-md-12 form-group">
                            <label for="message-form1-h" class="form-control-label mbr-fonts-style display-7">Messaggio</label>
                            <textarea name="message" data-form-field="Message" class="form-control display-7" id="message-form1-h" required ngModel></textarea>
                        </div>
                        <div class="col-md-12 input-group-btn align-center">
                            <button type="submit" class="btn btn-primary btn-form display-4" [disabled]="!postForm.valid">INVIA</button></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>