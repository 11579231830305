import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { Articoli } from 'src/app/models/articoli.model';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  art: Articoli[];

  constructor(private httpService: HttpService) {
    this.httpService.getArticoli().subscribe(
      (body: any) => {
        this.art = body.data;
      }
    )
   }

  ngOnInit(): void {
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
  }

}
