<section class="header6 cid-rWU8mfl41V mbr-fullscreen mbr-parallax-background" id="header6-2x">
    <div class="mbr-overlay" style="opacity: 0.5; background-color: rgb(35, 35, 35);">
    </div>

    <div class="container">
        <div class="row justify-content-md-center">
            <div class="mbr-white col-md-10">
                <h1 class="mbr-section-title align-center mbr-bold pb-3 mbr-fonts-style display-1">404<br>Non ho trovato la pagina che cercavi...</h1>
            </div>
        </div>
    </div>
</section>