import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { HomeComponent } from './component/home/home.component';
import { NoPageFoundComponent } from './component/no-page-found/no-page-found.component';
import { InvestmentsComponent } from './component/investments/investments.component';
import { HowToWorkComponent } from './component/how-to-work/how-to-work.component';
import { BlogComponent } from './component/blog/blog.component';
import { ContactComponent } from './component/contact/contact.component';
import { InvestmentComponent } from './component/investments/investment/investment.component';
import { NewsletterComponent } from './core/newsletter/newsletter.component';
import { ArticleComponent } from './component/blog/article/article.component';
import { MapComponent } from './component/map/map.component';
import { OpportunityCardComponent } from './component/shared/opportunity-card/opportunity-card.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CallToActionInvestmentComponent } from './component/shared/call-to-action-investment/call-to-action-investment.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from './component/shared/toast.service';
import { ToastsContainer } from './component/shared/toasts-container.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment.prod';
import { CookieServiceModule } from 'cookie-service-banner';
import { testLibConfig } from './app-cookie.constants';
import { PrivacyComponent } from './component/privacy/privacy.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NoPageFoundComponent,
    InvestmentsComponent,
    HowToWorkComponent,
    BlogComponent,
    ContactComponent,
    InvestmentComponent,
    NewsletterComponent,
    ArticleComponent,
    MapComponent,
    OpportunityCardComponent,
    CallToActionInvestmentComponent,
    ToastsContainer,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    CookieServiceModule,
    CookieServiceModule.forRoot(testLibConfig),
  ],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy},
    ToastService],
  bootstrap: [AppComponent]
})
export class AppModule { }
