import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './component/home/home.component';
import { NoPageFoundComponent } from './component/no-page-found/no-page-found.component';
import { InvestmentsComponent } from './component/investments/investments.component';
import { HowToWorkComponent } from './component/how-to-work/how-to-work.component';
import { BlogComponent } from './component/blog/blog.component';
import { ContactComponent } from './component/contact/contact.component';
import { InvestmentComponent } from './component/investments/investment/investment.component';
import { ArticleComponent } from './component/blog/article/article.component';
import { PrivacyComponent } from './component/privacy/privacy.component';

const routes: Routes = [
  {path: '', redirectTo: '/index', pathMatch: 'full'},
  {path: 'index', component: HomeComponent},
  {path: 'index/:mail', component: HomeComponent},
  {path: 'investments', component: InvestmentsComponent},
  {path: 'investment/:id', component: InvestmentComponent},
  {path: 'howtowork', component: HowToWorkComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'article/:id', component: ArticleComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'unsubscribe/:mail', redirectTo: '/index/:mail'},
  {path: 'privacy', component: PrivacyComponent},
  {path: '**', component: NoPageFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }