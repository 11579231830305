<section class="cid-rW28osp4Ub" id="menu1-v">
    <nav class="navbar navbar-expand-lg navbar-dropdown fixed-top navbar-toggleable-sm navbar-dark bg-dark">
        <button class="navbar-toggler navbar-toggler-right" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="menu-logo">
            <div class="navbar-brand">
                    <a [routerLink]="['/index']">
                         <!-- <img src="assets/images/logo.svg" alt="House Investing" > -->
                         <span >HOUSE INVESTING</span>
                    </a>

                <!-- <span class="navbar-caption-wrap">
                    <a class="navbar-caption text-white display-5" [routerLink]="['/index']">HOUSE INVESTING</a>
                </span> -->
            </div>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed"> 
            <ul class="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true" >
                <li class="nav-item" routerLinkActive="active"  >
                    <a class="nav-link link text-white display-4" [routerLink]="['/investments']">
                        <span class="mbri-home mbr-iconfont mbr-iconfont-btn"></span>Opportunità</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link link text-white display-4" [routerLink]="['/howtowork']"><span class="mbri-setting3 mbr-iconfont mbr-iconfont-btn"></span>
                        Come funziona</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link link text-white display-4" [routerLink]="['/blog']">
                        <span class="mbri-bookmark mbr-iconfont mbr-iconfont-btn"></span>Blog</a>
                    </li>
                    <li routerLinkActive="active" class="nav-item"><a class="nav-link link text-white display-4" [routerLink]="['/contact']"><span class="mbri-letter mbr-iconfont mbr-iconfont-btn"></span>
                        Contatti &nbsp;&nbsp;</a></li></ul>
            
        </div>
    </nav>
</section>
