<section class="mbr-section content4 cid-rW2bRHtpX8" id="content4-17">
    <div class="container">
        <div class="media-container-row">
            <div class="title col-12 col-md-10">
                <h2 class="align-center mbr-fonts-style display-2">Le opportunità di rendita immobiliare</h2>
            </div>
        </div>
    </div>
</section>
<section class="features3 cid-rW1Xe3SNiO" id="features3-16">
    <div class="container">
        <app-opportunity-card></app-opportunity-card>
    </div>
</section>
<!-- <section class="features3 cid-rW2bMAdnH3" id="features3-16">
    <div class="container">
        <div class="row">
            <div class="card p-3 col-12 col-md-6" *ngFor="let investment of inv">
                <div class="card-wrapper">
                    <div class="card-img">
                        <img src="{{investment.img}}" alt="Mobirise">
                    </div>
                    <div class="card-box">
                        <h4 class="card-title mbr-fonts-style display-7">
                            {{investment.title}}
                        </h4>
                        <p class="mbr-text mbr-fonts-style display-7">
                            {{investment.description}}
                        </p>
                    </div>
                    <div class="mbr-section-btn text-center"><a [routerLink]="['/investment/', investment.id]" class="btn btn-primary display-4">
                            Per saperne di più
                        </a></div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<app-newsletter></app-newsletter>