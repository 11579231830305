import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpService } from 'src/app/core/http/http.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  success: boolean;
  @ViewChild('postForm') postForm:NgForm;
  constructor(private http: HttpService) { }

  ngOnInit(): void {}

  onCreatePost(postData: { name: string; email: string, phone: string, message: string }) {
    // Send Http request
    this.http.postContatto(postData).subscribe(
      responseData =>{
        this.success = true;
        this.postForm.reset();
      },
      (error) => {
        console.log(error)
      });
    console.log(postData)
    // this.http
    //   .post(
    //     'https://ng-complete-guide-c56d3.firebaseio.com/posts.json',
    //     postData
    //   )
    //   .subscribe(responseData => {
    //     console.log(responseData);
    //   });
  }

}
